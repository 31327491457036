import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageWrapper, Header, PageTitle, Card, Link } from "../components"

import Travolta from "../images/travolta.gif"

const Image = styled.img`
  margin: 0;
  display: block;
`

const Back = styled.p`
  margin: 0.5rem 0;
  padding-left: 0.875rem;

  span {
    margin-left: 0.5rem;
    display: inline-block;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageWrapper>
      <Header>
        <Card>
          <PageTitle>Four? Oh, four!</PageTitle>
        </Card>
        <Card>
          <Image src={Travolta} />
        </Card>
        <Back>
          <Link href="/">
            <FontAwesomeIcon icon="igloo" color="#ffffff" fixedWidth />
            <span>Take me home</span>
          </Link>
        </Back>
      </Header>
    </PageWrapper>
  </Layout>
)

export default NotFoundPage
